// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/scss'
import 'swiper/scss/navigation'
// import required modules
import { Navigation } from 'swiper'
import './styles.scss'
import { Image } from 'src/components/ui/Image'

const FoundationTopCarousel = () => {
  return (
    <section className="foundation-carousel m-10 py-0 px-3">
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        loop
        navigation
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
        }}
        modules={[Navigation]}
        className="foundation mySwiper"
      >
        <SwiperSlide className="py-0 px-5">
          <Image
            src="https://decathlonproqa.vtexassets.com/arquivos/carrosel-projeto-1.png"
            alt="proj-1"
            width={407}
            height={314}
            loading="lazy"
          />
        </SwiperSlide>
        <SwiperSlide className="py-0 px-5">
          <Image
            src="https://decathlonproqa.vtexassets.com/arquivos/carrosel-projeto-3.png"
            alt="proj-3"
            width={407}
            height={314}
            loading="lazy"
          />
        </SwiperSlide>
        <SwiperSlide className="py-0 px-5">
          <Image
            src="https://decathlonproqa.vtexassets.com/arquivos/carrosel-projeto-4.png"
            alt="proj-4"
            width={407}
            height={314}
            loading="lazy"
          />
        </SwiperSlide>
        <SwiperSlide className="py-0 px-5">
          <Image
            src="https://decathlonproqa.vtexassets.com/arquivos/carrosel-projeto-5.png"
            alt="proj-5"
            width={407}
            height={314}
            loading="lazy"
          />
        </SwiperSlide>
        <SwiperSlide className="py-0 px-5">
          <Image
            src="https://decathlonproqa.vtexassets.com/arquivos/carrosel-projeto-6.png"
            alt="proj-6"
            width={407}
            height={314}
            loading="lazy"
          />
        </SwiperSlide>
        <SwiperSlide className="py-0 px-5">
          <Image
            src="https://decathlonproqa.vtexassets.com/arquivos/carrosel-projeto-7.png"
            alt="proj-7"
            width={407}
            height={314}
            loading="lazy"
          />
        </SwiperSlide>
        <SwiperSlide className="py-0 px-5">
          <Image
            src="https://decathlonproqa.vtexassets.com/arquivos/carrosel-projeto-8.png"
            alt="proj-8"
            width={407}
            height={314}
            loading="lazy"
          />
        </SwiperSlide>
        <SwiperSlide className="py-0 px-5">
          <Image
            src="https://decathlonproqa.vtexassets.com/arquivos/carrosel-projeto-9.png"
            alt="proj-9"
            width={407}
            height={314}
            loading="lazy"
          />
        </SwiperSlide>
        <SwiperSlide className="py-0 px-5">
          <Image
            src="https://decathlonproqa.vtexassets.com/arquivos/carrosel-projeto-10.png"
            alt="proj-10"
            width={407}
            height={314}
            loading="lazy"
          />
        </SwiperSlide>
        <SwiperSlide className="py-0 px-5">
          <Image
            src="https://decathlonproqa.vtexassets.com/arquivos/carrosel-projeto-11.png"
            alt="proj-11"
            width={407}
            height={314}
            loading="lazy"
          />
        </SwiperSlide>
        <SwiperSlide className="py-0 px-5">
          <Image
            src="https://decathlonproqa.vtexassets.com/arquivos/carrosel-projeto-12.png"
            alt="proj-12"
            width={407}
            height={314}
            loading="lazy"
          />
        </SwiperSlide>
        <SwiperSlide className="py-0 px-5">
          <Image
            src="https://decathlonproqa.vtexassets.com/arquivos/carrosel-projeto-13.png"
            alt="proj-13"
            width={407}
            height={314}
            loading="lazy"
          />
        </SwiperSlide>
        <SwiperSlide className="py-0 px-5">
          <Image
            src="https://decathlonproqa.vtexassets.com/arquivos/carrosel-projeto-14.png"
            alt="proj-14"
            width={407}
            height={314}
            loading="lazy"
          />
        </SwiperSlide>
      </Swiper>
    </section>
  )
}

export default FoundationTopCarousel
