import FoundationTopCarousel from 'src/components/sections/FoundationTopCarousel'
import FoundationBottomCarousel from 'src/components/sections/FoundationBottomCarousel'
import { Image } from 'src/components/ui/Image'

import SaberMais from './SaberMais'

const Fundacao = () => {
  return (
    <div className="my-0 mx-auto w-screen">
      <img
        src="https://decathlonstore.vtexassets.com/arquivos/Banner-decathlon-lp-fundacao-desktop.png"
        alt="fundacao decathlon"
        className="w-full"
      />
      <div className="bg-lighestGray text-xl lg:text-[32px] text-center tracking-wide font-black text-blue h-40 flex items-center justify-center w-screen">
        Mover as pessoas em situação de vulnerabilidade por meio das maravilhas
        do esporte
      </div>
      <div className="py-0 px-3">
        <h1 className="text-center text-black font-black tracking-wider xl:text-[32px] text-xl">
          FUNDAÇÃO DECATHLON <span className="text-blue">NO MUNDO</span>
        </h1>
        <div className="container text-center my-0 mx-auto">
          <p className="text-center text-veryDarkShadeOfGray tracking-wide my-4 mx-0 leading-6">
            A Fundação Decathlon foi fundada em 2005 com o objetivo de tornar o
            esporte acessível a todos. Para isso, são desenvolvidas iniciativas
            que levam o esporte e a integração às comunidades. Criamos uma
            ligação entre as associações, os beneficiários e os nossos
            colaboradores. A Fundação Decathlon foi fundada em 2005 com o
            objetivo de tornar o esporte acessível a todos. Para isso, são
            desenvolvidas iniciativas que levam o esporte e a integração às
            comunidades. Criamos uma ligação entre as associações, os
            beneficiários e os nossos colaboradores.
          </p>
          <p className="text-center text-veryDarkShadeOfGray tracking-wide my-4 mx-0 leading-6">
            Ao apoiar projetos de inserção através do esporte, a Fundação
            Decathlon permite que pessoas em situações de vulnerabilidade voltem
            a sorrir, tenham mais saúde, desenvolvam habilidades para a vida e
            tenham acesso a empregos.
          </p>
          <p className="text-center text-veryDarkShadeOfGray tracking-wide my-4 mx-0 leading-6">
            Para isso, a Fundação Decathlon se apoia em dois pilares
            fundamentais: o humano e o esportivo. É essa a combinação ideal para
            gerar um impacto grande, real e mensurável.
          </p>
        </div>

        <div className="max-w-[625px] h-[350px] flex items-center justify-center my-0 mx-auto">
          <iframe
            className="my-10 mx-auto w-full"
            width="100%"
            height="350"
            src="https://www.youtube.com/embed/fJyryUXjpSQ"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>

        <h1 className="text-center font-black tracking-wider xl:text-[32px] text-xl">
          FUNDAÇÃO DECATHLON <span className="text-blue">NO BRASIL</span>
        </h1>
        <div className="container text-veryDarkShadeOfGray text-center my-0 mx-auto leading-6">
          <p className="text-center text-veryDarkShadeOfGray  tracking-wide my-4 mx-0">
            No Brasil, a Fundação Decathlon existe desde 2005 e tem como
            propósito estimular e apoiar a participação da comunidade no
            esporte, promovendo integração social e estimulando a inserção no
            mercado de trabalho.
          </p>
          <p className="text-center text-veryDarkShadeOfGray tracking-wide my-4 mx-0 leading-6">
            Os colaboradores das lojas, serviços e centro de distribuição
            desempenham o papel de encontrar e apadrinhar projetos sociais que
            possuem a promoção da atividade esportiva à comunidade. A Fundação
            oferece às entidades apoio financeiro para revitalização de quadras
            e campos, compra de materiais esportivos e treinamento para funções
            esportivas, como ensinar a encordoar raquetes e fazer a revisão das
            bicicletas.
          </p>
          <p className="text-center text-veryDarkShadeOfGray tracking-wide my-4 mx-0 leading-6">
            No total, 45 000 pessoas, em sua maioria crianças, já foram
            beneficiadas pelas ações apoiadas pela Fundação.
          </p>
        </div>

        <FoundationTopCarousel />
      </div>
      <h1 className="text-center text-black font-black tracking-wider italic xl:text-[32px] text-xl">
        OS NOSSOS <span className="text-blue">PRINCIPAIS NÚMEROS</span>
      </h1>
      <div className="h-auto md:h-80 w-full bg-blue flex flex-col justify-center">
        <div className="grid grid-cols-1 md:grid-cols-4 font-semibold text-white my-0 mx-auto italic">
          <div className="flex justify-between xl:justify-center items-center md:grid ">
            <p className="text-[50px] xl:text-8xl md:text-[70px] text-center mb-5">
              20
            </p>
            <p className="text-center text-[17px] xl:text-base md:text-1xl md:w-52 text-white xl:my-0 xl:mx-auto mx-0 font-thin xl:w-full w-[45%]">
              projetos apoiados em 17 anos
            </p>
          </div>
          <div className="flex justify-between xl:justify-center  items-center md:grid ">
            <p className="text-[50px] xl:text-8xl md:text-[70px] text-center mb-5">
              8
            </p>
            <p className="text-center text-[17px] xl:text-base md:text-1xl text-white xl:my-0 xl:mx-auto mx-0 font-thin xl:w-full w-[45%]">
              estados contemplados
            </p>
          </div>
          <div className="flex justify-between xl:justify-center  items-center md:grid">
            <p className="text-[50px] xl:text-8xl md:text-[70px] text-center mb-5">
              +45 000
            </p>
            <p className="text-center text-[17px] xl:text-base md:text-1xl text-white xl:my-0 xl:mx-auto mx-0 font-thin xl:w-full w-[45%]">
              pessoas beneficiadas
            </p>
          </div>
          <div className="flex justify-between xl:justify-center  items-center md:grid ">
            <p className="text-[50px] xl:text-8xl md:text-[70px] text-center mb-5">
              +150
            </p>
            <p className="text-center text-[17px] xl:text-base md:text-1xl text-white xl:my-0 xl:mx-auto mx-0 font-thin xl:w-full w-[45%]">
              {' '}
              colaboradores envolvidos nos projetos
            </p>
          </div>
        </div>
      </div>
      <div className=" max-w-container my-0 mx-auto px-3">
        <h1 className="text-center text-black font-black tracking-wider italic xl:text-[32px] text-xl">
          PROJETOS APOIADOS <span className="text-blue">NO BRASIL</span>
        </h1>
        <FoundationBottomCarousel />
      </div>
      <div className=" max-w-container my-0 mx-auto px-3">
        <div className="flex items-center flex-col justify-center max-w-[500px] my-3 mx-auto gap-[10px]">
          <h1 className="text-center text-black font-black tracking-wider italic  my-0 mx-auto xl:text-[32px] text-xl">
            COMO TER UM PROJETO{' '}
            <span className="text-blue font-black tracking-wider italic  my-0 mx-auto xl:text-[32px] text-xl">
              APOIADO PELA FUNDAÇÃO DECATHLON?
            </span>
          </h1>
        </div>

        <div className="max-w-[625px] h-[350px] flex items-center justify-center my-0 mx-auto">
          <iframe
            className="my-10 mx-auto w-full"
            width="100%"
            height="350"
            src="https://www.youtube.com/embed/apwh8oSKDIk"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        <p className="text-center text-gray">
          E-mail para contato:{' '}
          <span className="text-blue">
            fundacao.decathlonbrasil@decathlon.com
          </span>
        </p>
        <h1 className="text-center text-blue font-black xl:text-[32px] text-xl">
          FOUNDATION DAY
        </h1>
        <div className="flex flex-col justify-center items-center xl:flex-row xl:flex md:flex-col md:items-center md:justify-center container my-0 mx-auto">
          <div className="w-full max-w-[605px]">
            <Image
              src="https://decathlonstore.vtexassets.com/arquivos/foundation-day.png"
              alt="carousel next arrow"
              width={605}
              height={300}
              loading="lazy"
            />
          </div>
          <div className="flex justify-top flex-col xl:w-6/12 w-full">
            <p className="text-base mb-8 ml-4 text-veryDarkShadeOfGray tracking-[.1vw] leading-6">
              O Foundation Day é o evento oficial da Fundação Decathlon, e
              acontece sempre na primeira quarta-feira de junho quando as nossas
              lojas e o Centro de Distribuição abrem as portas para receber e
              proporcionar a prática esportiva às pessoas em situação de
              vulnerabilidade.
            </p>
            <p className="text-base ml-4 text-veryDarkShadeOfGray tracking-[.1vw] leading-6">
              O objetivo é incentivar a prática esportiva possibilitando a
              integração social. Mas também é uma oportunidade de aproximar os
              colaboradores aos projetos sociais, próximos ao seu local de
              trabalho ou casa, e que possam ser beneficiados pelo apoio da
              Fundação.
            </p>
          </div>
        </div>
        <SaberMais />
      </div>
    </div>
  )
}

export default Fundacao
