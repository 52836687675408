import { Image } from 'src/components/ui/Image'

const SaberMais = () => {
  return (
    <div className="h-80 bg-lighestGray">
      <h1 className="font-semibold text-center pt-12 text-gray xl:text-[32px] text-xl my-3 mx-0">
        QUER SABER MAIS?
      </h1>
      <p className="text-center text-black tracking-[.1vw] leading-6">
        Conheça os canais oficiais da Fundação Decathlon no mundo!
      </p>
      <div className="flex flex-col justify-center items-center xl:flex-row xl:gap-[215px] gap-[50px]">
        <div className="grid grid-row-2">
          <Image
            src="https://decathlonproqa.vtexassets.com/arquivos/foundation.svg"
            alt="icon foundation"
            className="flex my-0 mx-auto mt-4"
            width={32}
            height={32}
            loading="lazy"
          />
          <p className="m">www.foundationdecathlon.com</p>
        </div>

        <div className="grid grid-row-2">
          <Image
            src="https://decathlonproqa.vtexassets.com/arquivos/instagram.svg"
            alt="icon instagram"
            className="flex my-0 mx-auto mt-4"
            loading="lazy"
            width={32}
            height={32}
          />
          <p>@decathlonfoundation</p>
        </div>
      </div>
    </div>
  )
}

export default SaberMais
